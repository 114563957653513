import React from "react";

const PageTitlePortfolio = () => {
  return (
    <section id="page-title">
      <div className="wrapper">
        <div className="title">
          <h1 className="large">Portfolio.</h1>
        </div>

        <div className="description-2">
          <p>
            Welcome to my portfolio, take a look at some of the photos from
            these wonderful weddings.{" "}
          </p>
        </div>
      </div>
    </section>
  );
};

export default PageTitlePortfolio;
