import React from "react";

const PageTitleContacts = () => {
  return (
    <section id="page-title">
      <div className="wrapper">
        <div className="title">
          <h1 className="large">Contacts.</h1>
        </div>

        <div className="description-2">
          <p>Feel free to contact me via Bark. </p>
        </div>
      </div>
    </section>
  );
};

export default PageTitleContacts;
