import React from "react";

const AboutContent = () => {
  return (
    <div id="about" className="block spacer p-top-xl">
      <div className="title">
        <h2>About me</h2>
      </div>

      <div className="row gutter-width-md with-pb-md">
        <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
          <h5>Im am a wedding, pet and portrait photographer.</h5>
        </div>

        <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
          <p>
            My name is Daniel Haddon and I have a love and passion for
            photography. Let me help tailor your special day to give you
            memories you will cherish forever.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
