import React from "react";

const PageTitleHome = () => {
  return (
    <section id="page-title" className="block">
      <div className="wrapper">
        <div className="page-title-content">
          <div className="page-title-img">
            <img
              style={{ opacity: 0.5 }}
              src="assets/img/demo/Camera.png"
              alt="Happy Anniversary"
            />
          </div>

          <div className="page-title-description">
            <div className="title">
              <h1 className="large">
                Daniel Haddon
                <br />
                Photography.
              </h1>
            </div>

            <div className="description">
              <p>
                My mission is to ensure you remember the joy of your special
                day.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTitleHome;
