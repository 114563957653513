import React from "react";

import Gallery from "../gallery/Gallery";

const HomePortfolio = () => {
  return (
    <section id="porfolio" className="block spacer p-top-xl">
      <div className="wrapper">
        <div className="title">
          <h2>My Portfolio</h2>
        </div>

        <div className="description max-width-2">
          <p>Take a look at some of our wonderful wedding photos.</p>
        </div>

        <div className="">
          <Gallery />
        </div>
      </div>
    </section>
  );
};

export default HomePortfolio;
