import React from "react";

const PageTitleAbout = () => {
  return (
    <section id="page-title">
      <div className="wrapper">
        <div className="title">
          <h1 className="large">About.</h1>
        </div>

        <div className="description-2">
          <p>I am a freelance photographer with a focus on weddings. </p>
        </div>
      </div>
    </section>
  );
};

export default PageTitleAbout;
